module.exports = {
  presidentName: "Bud C.Smith",
  letterTitle: "Winter Message",
  letterParagraphs: [
    "Winter is upon us, and like all mountain roads, we have our fair share of trees coming down blocking the roadway, along with mud and rock slides. We will continue to keep on top of these issues in order to keep the property open for your use year-round.",
    "This past summer, Logan Pope (Boy Scout troop 90 from Brentwood, Ca.) completed his Eagle Scout project in our campground. Logan designed and constructed a food storage cabinet near tent cabin number 9. The cabinet is a welcome addition to our tent cabin camping facility. Additionally, this past summer, member Pete Kutch and his wife Debbie (<a href=\"https://voicesconnect.com/\">hhttps://voicesconnect.com/ </a>) did an excellent job creating a 60th Anniversary video showcasing our association from the early days in 1964 to its present-day operations. Check out the video using this link: <a href=\"https://www.youtube.com/watch?v=m22qQQIld-k&ab_channel=VoicesConnect\">https://www.youtube.com/watch?v=m22qQQIld-k&ab_channel=VoicesConnect</a>",
    "On Saturday May 10th 2025 from 9-2pm at 24090 Deer Path Rd., Saratoga, we will be celebrating our 60th anniversary. This will be a fun filled afternoon, delicious food, games and more! This is an exclusive event for our members and their guests/sponsors.",
    "Please no tree cutting / wood removal without prior approval of the board of directors as the cutting down / harvesting of some trees require a permit. While using our range facility, be mindful that we do have neighbors that can hear the shooting from our range, so no big caliber handguns in the early morning hours and no night shooting.  Let's continue to be good neighbors.",
    "As it has been for over 55 years, the work party dates are the 2nd Saturday of every month and on those days, the range is CLOSED. During the Work Party Dates, we are maintaining the tent cabins, playground structures, bathrooms, water inlet system, range facility and constantly working on the roads. Please help us maintain the association property by volunteering a Saturday and enjoying a BBQ lunch with our all-volunteer property maintenance crew. Work party date or not, if there is a property improvement project you (with or without others) would like to do (i.e.: rebuilding the campfire area, clearing and remarking the hiking trails etc... call us at 264-1224 so the project and supplies can be discussed.",
    "There are several vacant agency representative positions on our board. All that is required is a little of your time as we meet on a monthly basis for about an hour, usually on a Tuesday, at a local pizza parlor.  All we ask is that you pass out applications and answer questions from those at your department.  There is no obligation to attend the monthly work parties (the second Saturday of every month), even though additional help is always welcome.  Call (408) 264-1224 for the date, time, and location of our board/membership meetings.",
    "Be sure to check out our Facebook page, post a picture or a review of your day at our facility. <a href=\"https://www.facebook.com/SCCPOA/\">https://www.facebook.com/SCCPOA/</a>"
  ],
  letterValediction:
    "<br/>Until next time stay safe,<br/> <br/><i>Bud C. Smith</i> <br/>SCCPOA President",
};
